import { Fragment, useState ,useEffect} from "react";
import React from "react";
import "../App.css";
import { PiMapPinFill } from "react-icons/pi";
import what2 from "../assets/images/what2.jpg";
import what3 from "../assets/images/what3.jpg";
import { MdEmail } from "react-icons/md";
import Banner from "../assets/images/banner.jpg";
import const1 from "../assets/images/const1.jpg";
import partner1 from "../assets/images/partner1.jpg";
import partner2 from "../assets/images/partner2.jpg";
import partner3 from "../assets/images/partner3.jpg";
import partner4 from "../assets/images/partner4.jpg";
import partner5 from "../assets/images/partner5.png";
import partner6 from "../assets/images/partner6.jpeg";
import partner7 from "../assets/images/partner7.png";
import partner8 from "../assets/images/partner8.jpg";
import project1  from "../assets/images/project1.jpg";
import project2 from "../assets/images/project2.jpeg";
import project3 from "../assets/images/project3.jpeg";
import project4 from "../assets/images/project4.jpg";
import project5 from "../assets/images/project5.jpg";
import project6 from "../assets/images/project6.jpg";
import project7 from "../assets/images/project7.jpg";
import project8 from "../assets/images/project8.jpg";
import carsel from "../assets/images/carsel.jpg";
import cranes from "../assets/images/cranes.jpg";
import machine from "../assets/images/machine.jpg";
import steel from "../assets/images/steel.jpg";
import ac from "../assets/images/ac.jpg";
import doors from "../assets/images/doors.jpg";
import { Container, Row, Col } from "react-bootstrap";
import person from "../assets/images/perso1.png";
import OwlCarousel from "react-owl-carousel";
import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
function home() {
 
  jQuery("#carousel").owlCarousel({
    autoplay: true,
    dots: false,
    rewind: false, /* use rewind if you don't want loop */
    margin: 20,
    loop: true,
     /*
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    */
    responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 1000,
    smartSpeed: 1000,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
  
      600: {
        items: 3
      },
  
      1024: {
        items: 4
      },
  
      1366: {
        items: 4
      }
    }
  });


  const styles = {
    button: {
        position: 'fixed',
        bottom: '50px',
        right: '50px',
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        backgroundColor:'blue',
        color: '#fff',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        transition: 'background-color 0.3s ease'
    }
};
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
          if (window.pageYOffset > 300) {
              setShowButton(true);
          } else {
              setShowButton(false);
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data submitted:', formData);
  };
  const options = {
    loop: true,
    autoplay: true,
    margin: 10,           
    responsiveClass: true,
    responsive: true,   
    responsive: {
      0: {
        items: 1,
        nav: true,
        dots: false,
      },
      600: {
        items: 1,
        nav: true,
        dots: false,
      },
      1000: {
        items: 1,
        dots: true,
        nav: true,
      },
    },
  };
  const target = {
    loop: true,
    autoplay: true,
    margin: 10,
    responsiveClass: true,
    responsive: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
        dots: false,
      },
      600: {
        items: 4,
        nav: false,
        dots: true,
      },
      1000: {
        items: 4,
        dots: true,
      },
    },
  };
  return (
    <Fragment>
      <section className="main-banner-head" id="news">
        <Row>
          <Col>
            <OwlCarousel
              className="owl-theme"
              loop={false}
              items={1}
              margin={10}
              nav={false}
              autoplay={true}
              autoplaySpeed={2000}
              {...options}
            >
              <div className="item">
                <img src={Banner} alt="banner" height={600}/>
              </div>
              <div className="item">
                <img src={cranes} alt="banner" height={600}/>
              </div>
            </OwlCarousel>
          </Col>
        </Row>
      </section>
      <section className="yellow-bg" id="about-us">
        <Container>
          <h2 className="pt-4">Consger</h2>
          <h2 className="pt-2 mb-0">
            Ist ein Lieferant für die internationale Bauindustrie.
          </h2>
        </Container>
      </section>
      <section className="main-banner-head">
        <Row>
          <Col lg={6}>
            <Container>
              <h3 className="pt-3 pb-3">Unternehmensgeschichte und Ziele</h3>
              <p>
                Das Unternehmen Conser wurde in schlechtem Neuenahr-Ahrweiler, Deutschland, gegründet und ist
Registriert als internationale Bauunternehmen
Ziel ist es, die Zukunft des Bauwesens durch wegweisende umweltfreundliche Gebäude zu gestalten
Praktiken und Förderung langfristiger Partnerschaften
Mit Kunden und Gemeinden, spezialisiert auf die vollständige Versorgung von
Waren in die Bauindustrie. <br/> Das Unternehmen verpflichtet sich kontinuierlich
Verbesserung, Investitionen in die neuesten Technologien und Mitarbeiterentwicklung zu
Halten Sie seinen Wettbewerbsvorteil beibehalten.
Letztendlich wollen wir weltweit wachsen und operieren.
Erweitern Sie unser Geschäft sogar über diese Märkte hinaus.
              </p>
              <p>
                Das Erreichen dieser Ziele umfasst unter anderem unter anderem.
Unterzeichnung langfristiger Händler- und Lizenzverträge überall
Die Welt und an internationalen Ausschreibungen teilnehmen, wo
Conger ist entweder der Hauptauftragnehmer oder a
Subunternehmer.
              </p>
              <p className="para-mobile">
               Unser Team, das aus einem Geschäftsführer besteht, Aktionäre,
Und sachkundige Manager haben umfangreiche Erfahrung in zahlreichen
Bedeutende globale Marktplätze.
              </p>
            </Container>
          </Col>
          <Col lg={6}>
            <img src={person} className="person" />
          </Col>
        </Row>
      </section>
      <section className="black-bg">
        <Container>
          <Row className="pt-5 pb-5">
            <Col sm={12} md={12} lg={3} className="category-img pt-5 pb-5">
              <img src={machine} className="machine" />
              <h4 className="category">Maschinen</h4>
            </Col>
            <Col sm={12} md={12} lg={3} className="category-img pt-5 pb-5">
              <img src={steel} className="machine" />
              <h4 className="category">Baumaterial</h4>
            </Col>
            <Col sm={12} md={12} lg={3} className="category-img pt-5 pb-5">
              <img src={ac} className="machine" />
              <h4 className="category">Klimaanlage</h4>
            </Col>
            <Col sm={12} md={12} lg={3} className="category-img pt-5 pb-5">
              <img src={doors} className="machine" />
              <h4 className="category">Windows & Türen</h4>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="main-banner-head" id="what-we-do">
      <h1 className="pt-5 mb-0 what-section">WAS WIR TUN</h1>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <h2 className="what-head-sub pt-5">1 - Allgemeine / strukturelle Bauherren</h2>
              <h5 className="what-sub-2">Versorgung sowie Bauingenieurwesen</h5>
              <p>
               Die folgenden Produkte werden fachmännisch von Conser versorgt:
                </p>
                <ul>    
                <li>Rohstoffe, einschließlich Sand, Beton, Ziegel und
Granatwerfer.</li>
                <li> Gebäudelieferungen sowohl für Innen- als auch für Äußere
Gebäude.</li>
                <li> Einschließlich Farbe, Wandbedeckungen, Teppichböden, Steine,
Metall-, Stahl-, Holz-, Glas- und Plastik -Tischlerei und Mauerwerk</li>
                <li>
               Materialien für Isolationsgerüste und Stahlkonstruktionen
Dachlieferungen
                </li>
                <li> Geräte, Zubehör und Teile für elektrische und elektronische Installationen und Ausstattung.</li>
                <li>
               Werkzeuge, Zubehör, Ersatzteile, Verbrauchsmaterialien (z. B. Muttern und Schrauben usw.)
                </li>
                <li>Wasserversorgungsgeräte, Wasserrohre, Klempner
Sanitäranlagen</li>
                <li>
               Heizungs- und Klimaanlagen, Systeme,
Tischlerei, Möbel und Armaturen
                </li>
                <li>
              Fenster und Türlösungen,
Bodenbelag,
Heimtechnologie,
Sicherheitstechnologie.
                </li>
                </ul>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <img src={const1} className="w-100"/>
            </Col>
          </Row>
      <Row>
      <Col sm={12} md={12} lg={6} className="what-mobile">
              <img src={what3} className="w-100"/>
            </Col>
      <Col sm={12} md={12} lg={6} className="pt-5 pb-5">
              <h2 className="pt-5 what-head-sub">2 - Außenbauer</h2>
              <h5 className="what-sub-2">Versorgung der Außenbauer</h5>
              <p>
              Die folgenden Aufgaben von außen können übertragen werden
Durch Konsum mit dem Erwerb von Maschinen,
Werkzeuge, Geräte und Zubehör: Terrassen, Garagen, Automobilanschlüsse,
und schwimmende Decks aus Gartenausgrabungen für Pflastersteine
mit Wänden und Zäunen <br/> Laibs und Dachrinnen mager zu Pools und Teichen
              </p>
            </Col>
      </Row>
      <Row>
      <Col sm={12} md={12} lg={6} className="pt-5">
              <h2 className="pt-5 what-head-sub">3 - Nach-Pflege Liefern Dienstleistungen</h2>
              <h5 className="what-sub-2">Verkaufs- und Versorgungsdienstleistungen</h5>
              <p>
             Nachdem die Bauprojekte beendet sind, können sich unsere Kunden auf uns verlassen
Um ihre Bedürfnisse nach Materialien, Ausrüstung und Verbrauchsmaterial zu erfüllen
Sowie Ersatzteile und Zubehör zu
Aktualisieren, ersetzen und/oder erweitern Sie die aktuellen Installationen.

              </p>
            </Col>
            <Col sm={12} md={12} lg={6} className="what-last-mobile">
              <img src={what2} className="w-100"/>
            </Col>
      </Row>
      </section>
      <section className="recent-project" id="projects">
      <h2 className="recent-heading pt-5">Recent Projects</h2>
      <div class="owl-slider">
<div id="carousel" class="owl-carousel">
	<div class="item">
		<img src={project1} alt="1000X1000"  height={350}/>
	</div>
	<div class="item">
		<img src={project2} alt="" height={350}/>
	</div>
	<div class="item">
		<img src={project3} alt=""height={350}/>
	</div>
	<div class="item">
		<img src={project4} alt="" height={350}/>
	</div>
  	<div class="item">
		<img src={project5} alt="" height={350}/>
	</div>
	<div class="item">
		<img src={project6} alt="" height={350}/>
	</div>
	<div class="item">
		<img src={project7} alt=""  height={350}/>
	</div>
	<div class="item">
		<img src={project8} alt="" height={350}/>
	</div>
</div>
</div>
    </section>
      {/* <section className="recent-project" id="projects">
      <h2 className="recent-heading pt-5">Recent Projects</h2>
      <div className="marquee">
        <div className="marquee-inner">
          <img src={project1} className="project-img" alt="Project 1"/>
          <img src={project2} className="project-img" alt="Project 2"/>
          <img src={project3} className="project-img" alt="Project 3"/>
          <img src={project4} className="project-img" alt="Project 4"/>
          <img src={project5} className="project-img" alt="Project 5"/>
          <img src={project6} className="project-img" alt="Project 6"/>
          <img src={project7} className="project-img" alt="Project 7"/>
          <img src={project8} className="project-img" alt="Project 8"/>
        </div>
      </div>
    </section> */}
      <section className="partners">
        <Container>
          
            <h2 className="partner-head pt-5">Unsere Partner</h2>
            <Row className="pt-5 pb-5">
            <OwlCarousel
              className="owl-theme"
              items={4}
              autowidth={true}
              dots={false}
              loop
              margin={10}
              autoplaySpeed={1000} 
              autoplayTimeout={1000}
              rtl={true}
              nav={true}
              {...target}
            >
              <div className="item">
              <img src={partner1}/>
              </div>
              <div className="item">
              <img src={partner2}/>
              </div>
              <div className="item">
              <img src={partner3}/>
              </div>
              <div className="item">
              <img src={partner4}/>
              </div>
              <div className="item">
              <img src={partner5}/>
              </div>
              <div className="item">
              <img src={partner6}/>
              </div>
              <div className="item">
              <img src={partner7}/>
              </div>
              <div className="item">
              <img src={partner8}/>
              </div>
              <div className="item">
              <img src={carsel}/>
              </div>

            </OwlCarousel>
          
          </Row>
        </Container>
      </section>
      <section className="yellow-bg-1">
        <Container>
          <h3 className="pt-4">Conser ist ein Lieferant für die internationale Bauindustrie.
            </h3>
        </Container>
      </section>
      <section className="Contact" id="contact">    
      <Container>   
      <h2 className=" pt-5 contact-head">Kontaktiere uns</h2>
      <p  className="partner-head"> Mit unseren engagierten Mitarbeitern werden Sie nie herumhängen lassen</p>
            </Container>
            <Container>
              <Row>
                <Col lg={4} md={12} sm={12}>
                <div className="d-flex pt-4">
                  <div>
                  <PiMapPinFill className="pin"/>
                  </div>
                  <div>
                    <h4>Adresse</h4>
                    <p>Ummelner Straße <br/>12-14 , 33649 <br/>Bielefeld, Germany </p>
                  </div>
                </div>
                <div className="d-flex pt-4">
                  <div>
                  <MdEmail className="pin"/>
                  </div>
                  <div>
                    <h4>Email</h4>
                    <p>info@consger.com</p>
                  </div>
                </div>
                </Col>
                <Col lg={8} md={12} sm={12}>
                <div className="form-container">
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="name">Voll&nbsp;name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
       <label htmlFor="phone">Telefonnummer</label>
<input
  type="tel"
  id="phone"
  name="phone"
  value={formData.phone}
  onChange={handleChange}
  required
/>

        <label htmlFor="email">email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <br/>
        <label htmlFor="message">Nachricht</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        <button type="submit">Einreichen</button>
      </form>
    </div>
                </Col>
              </Row>
            </Container>
      </section>
      {showButton && (
                <button onClick={scrollToTop} style={styles.button}>
                    ↑ 
                 Zurück nach oben
                </button>
            )}
    </Fragment>
  );
}

export default home;
