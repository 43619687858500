import React from "react";

import "../App.css";
import project1 from "../assets/images/project1.jpg";
import project2 from "../assets/images/project2.jpeg";
import project3 from "../assets/images/project3.jpeg";
import project4 from "../assets/images/project4.jpg";
import project5 from "../assets/images/project5.jpg";
import project6 from "../assets/images/project6.jpg";
import project7 from "../assets/images/project7.jpg";
import project8 from "../assets/images/project8.jpg";
import OwlCarousel from "react-owl-carousel";

function Projects() {
  const options = {
    autoplay: true,
    dots: false,
    rewind: false,
    margin: 20,
    loop: true,
    responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 1000,
    smartSpeed: 1000,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1024: {
        items: 4,
      },
      1366: {
        items: 4,
      },
    },
  };

  return (
    <section className="recent-project mt-5 mb-5" id="projects">
      <h2 className="recent-heading pt-5">Jüngste Projekte</h2>
      <OwlCarousel className="owl-theme" {...options}>
        <div className="item">
          <img src={project1} alt="Project 1" height={350} />
        </div>
        <div className="item">
          <img src={project2} alt="Project 2" height={350} />
        </div>
        <div className="item">
          <img src={project3} alt="Project 3" height={350} />
        </div>
        <div className="item">
          <img src={project4} alt="Project 4" height={350} />
        </div>
        <div className="item">
          <img src={project5} alt="Project 5" height={350} />
        </div>
        <div className="item">
          <img src={project6} alt="Project 6" height={350} />
        </div>
        <div className="item">
          <img src={project7} alt="Project 7" height={350} />
        </div>
        <div className="item">
          <img src={project8} alt="Project 8" height={350} />
        </div>
      </OwlCarousel>
    </section>
  );
}

export default Projects;