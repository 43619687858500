import React from 'react'
import { useState } from 'react';
import "../Component/Footer.css";
import { Container,Row,Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
function Footer() {
      // -----sort-by-dropdown--------
  const [selectedItem, setSelectedItem] = useState("Deutsch");

  const handleSelect = (eventKey) => {
    setSelectedItem(eventKey);
  };
  return (
    <div>
    <section className="footer-last-pbx  pt-5">
    <Container>
    <Row>
    <Col sm={12} md={6} lg={3}>
  <h4 className='about-us-style'>Über uns</h4>
    <p className='footer-para pt-3 pb-3'>
    Consger wurde in schlechtem Neuenahr-Ahrweiler gegründet,
Deutschland als internationale Bauunternehmen
dessen Hauptziel ist die volle Bereitstellung von Waren an
die Bauindustrie
    </p>
    </Col>
    <Col sm={12} md={6} lg={3}>

                        <h4 className="about-us-style">Kontaktiere uns</h4>
                     <p className='footer-para pt-3 pb-3'> 
                     Ummelner Straße 12-14 , 33649 Bielefeld, Germany<br/> 
                     <a href="#info" className='coneger-mail'>info@consger.com</a> 
                     <br/>
                     <a href='#hr' className='coneger-mail'>hr@consger.com </a>
                     </p>
                     </Col>
                     <Col sm={12} md={6} lg={3}>

<h4 className="about-us-style">Zweigstelle in Teheran</h4>
<p className='footer-para pt-3 pb-3'>
Consger<br/>
Gebäude 11, Arman Ddend, Motahari Avenue<br/>
teheran,1565895844,Iran<br/>
</p>

                     </Col>
    </Row>
    </Container>
    </section>
 <section className='copyright'>    
    <Container>
        <Row>
            <Col lg={6}>
                <p className='copy-para'>Alle&nbsp;Rechte&nbsp;vorbehalten&nbsp;|&nbsp;@2024&nbsp;Consger</p>
            </Col>
            <Col lg={6}>
              
            </Col>
        </Row>
        </Container>
</section>
</div>
  )
}

export default Footer