import {React,useEffect,useState} from 'react';
import {Container} from "react-bootstrap";
import news1 from "../assets/images/banner.jpg";
import news2 from "../assets/images/news2.jpg";
import news3 from "../assets/images/news3.jpg";
import news4 from "../assets/images/news4.jpg";
import news5 from "../assets/images/news5.jpg";
import news6 from "../assets/images/news6.jpeg";
import news7 from "../assets/images/news7.jpeg";
import news8 from "../assets/images/news8.jpeg";
import news9 from "../assets/images/news9.jpg";
import news10 from "../assets/images/news10.jpg";
import news11 from "../assets/images/new11.jpg";
import news12 from "../assets/images/news12.jpeg";
import news13 from "../assets/images/news13.jpeg";
import news14 from "../assets/images/news14.png";
import "../App.css";
function News() {
    const styles = {
        button: {
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            backgroundColor:'black',
            color: '#fff',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px',
            cursor: 'pointer',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            transition: 'background-color 0.3s ease'
        }
    };
    const [showButton, setShowButton] = useState(false);
    
      useEffect(() => {
          const handleScroll = () => {
              if (window.pageYOffset > 300) {
                  setShowButton(true);
              } else {
                  setShowButton(false);
              }
          };
    
          window.addEventListener('scroll', handleScroll);
    
          return () => {
              window.removeEventListener('scroll', handleScroll);
          };
      }, []);
    
      const scrollToTop = () => {
          window.scrollTo({
              top: 0,
              behavior: 'smooth'
          });
      };
    
  return (
    <> 
    <section>
    <Container> 
    <h2 className="pt-5 pb-3 news">Nachricht</h2>
    <div class="media">
  <img class="mr-3 me-5" src={news1} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5 card-heading">Imperien JV für „komplexes“ 550 Mio. € Tiefsee-Tunneling-Projekt ausgewählt</h5>
    <span className='card-para'>Veröffentlicht am: 31. Mai 2024</span>
    <p class="card-text">Implenie wird einen großen Tunnel für diese europäische Insel bauen</p>
  </div>
</div>
<div className='pt-5'>
      <div class="media">
  <img class="mr-3 me-5" src={news2} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5 card-heading">Auf einen Blick: 36-Stunden-Betongur für Miamis Okan Tower</h5>
    <span className='card-para'>Veröffentlicht am: 31. Mai 2024</span>
    <p class="card-text">Die Okan Tower Foundation von Miami wurde während 36 Stunden in Folge eingegossen</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news3} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5 card-heading">
Holcim, Peri Anspruch 3D-Druck "First" in der Schweiz</h5>
    <span className='card-para'>Veröffentlicht am: 31. Mai 2024</span>
    <p class="card-text">Holcim Leads Build des „ersten“ 3D-gedruckten Gebäudes der Schweiz</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news4} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5 card-heading">Vinci zum Bau Wasserpflanzenerweiterung in Kambodscha</h5>
    <span className='card-para'>Veröffentlicht am: 31. Mai 2024</span>
    <p class="card-text">Vinci wird eine Verlängerung für eine Wasseraufbereitungsanlage in Südostasien errichten</p>
  </div>
</div>
</div>
 <div className='pt-5'>
 <div class="media">
  <img class="mr-3 me-5" src={news5} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5 card-heading">Imperien JV für „komplexes“ 550 Mio. € Tiefsee-Tunneling-Projekt ausgewählt</h5>
    <span className='card-para'>Veröffentlicht am: 31. Mai 2024</span>
    <p class="card-text">Implenie wird einen großen Tunnel für diese europäische Insel bauen</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news6} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5 card-heading">Epiroc beendet den Kauf der autonomen Bergbaugesellschaft</h5>
    <span className='card-para'>Veröffentlicht am: 31. Mai 2024</span>
    <p class="card-text">EPIROC Käufe verbleibender Anteil an OEM Agnostic Autonomous Mining Solutions Company</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news7} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5  card-heading">EFCA:‘Ingenieur Europas
    <br/>bleiben stark und optimistisch’</h5>
    <span className='card-para'>Veröffentlicht am: 31. Mai 2024</span>
    <p class="card-text">Die Mehrheit der Länder, die stabile oder steigende Märkte melden</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news8} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5  card-heading">Warum werden Deutschlands Bauarbeiter in Streik streiken?</h5>
    <span className='card-para'>Veröffentlicht auf: 06. Februar, 2022</span>
    <p class="card-text">Die Arbeiter zum Streik nach dem Deal wurden abgelehntden abgelehntden abgelehnt</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news9} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5  card-heading">Züblin constructs ‘world first’ load-bearing concrete walls with 3D printer</h5>
    <span className='card-para'>Published on: 16 May, 2020</span>
    <p class="card-text">Company says it has printed concrete structure with load-bearing walls</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news10} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5  card-heading">Haupt neue Einrichtung in Europa für TopCon</h5>
    <span className='card-para'>Veröffentlicht am: 02. April, 2020</span>
    <p class="card-text">Neue Einrichtung wird die Anzahl der jährlich produzierten Einheiten des Technologiegiganten erheblich erhöhen</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news11} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5  card-heading">Wackers neue elektrische und digitale Angebote</h5>
    <span className='card-para'>Veröffentlicht am: 11. Juni, 2019</span>
    <p class="card-text">
                               OEM aus Deutschland präsentierte eine Reihe von Geräten und Angeboten bei Intermat 2024
                            </p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news12} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5  card-heading">Helfen leichte Winter die europäische Baueffizienz?</h5>
    <span className='card-para'>Veröffentlicht am: 12. Mai, 2019</span>
    <p class="card-text">Wie ein globaler Erwärmungs -Trend bei der Bautätigkeit des Winterbaus hilft</p>
  </div>
</div>
</div>
<div className='pt-5'>
<div class="media">
  <img class="mr-3 me-5" src={news13} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5  card-heading">Arbeiter bei tödlichen Vorfällen am Fehmarnbelt -Tunnelstandort in Deutschland</h5>
    <span className='card-para'>Veröffentlicht am: 15. September, 2018</span>
    <p class="card-text">
                                Ein Arbeiter wurde nach einem schweren Arbeitsunfall getötet
                            </p>
  </div>
</div>
</div>
<div className='pt-5 pb-5'>
<div class="media">
  <img class="mr-3 me-5" src={news14} alt="Generic placeholder image" height={200} width={200}/>
  <div class="media-body">
    <h5 class="mt-5  card-heading">Eiffage kauft einen großen Akteur auf dem Markt für europäische Energieinfrastruktur</h5>
    <span className='card-para'>Veröffentlicht am: 02. Mai, 2018</span>
    <p class="card-text">
                               Die französische Baugruppe Eiffage hat EQOs erworben, einen großen europäischen Akteur auf den Energieinfrastrukturmärkten</p>
  </div>
</div>
</div>
</Container>
    </section>
    {showButton && (
                <button onClick={scrollToTop} style={styles.button}>
                    ↑ Zurück nach oben
                </button>
            )}
    </>
  )
}

export default News