import { React, Fragment, useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import "../App.css";
function Careeropportunities() {
  const styles = {
    button: {
        position: 'fixed',
        bottom: '50px',
        right: '50px',
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        backgroundColor:'black',
        color: '#fff',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        transition: 'background-color 0.3s ease'
    }
};
const [showButton, setShowButton] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
          if (window.pageYOffset > 300) {
              setShowButton(true);
          } else {
              setShowButton(false);
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
  };

  const sortByDate = () => {
    const sortedDates = [...dates].sort((a, b) => new Date(b) - new Date(a));
    setDates(sortedDates);
  };

  const history = useHistory();

  const handleRowClick = () => {
    history.push("/contact");
  };

  return (
    <Fragment>
      <section>
        <Container>
          <Row>
            <h1 className="pt-5 pb-5">Career&nbsp;Opportunities</h1>
            {/* <div>
              <button onClick={sortByDate}>Sort Dates</button>
              <ul>
                {dates.map((date, index) => (
                  <li key={index}>{date}</li>
                ))}
              </ul>
            </div> */}
            <Table  responsive>
            <thead>
                <tr >
                  <th className="table-row" scope="col">
                              Arbeit Titel
                  </th>
                  <th scope="col">
                   Position
                  </th>
                  <th scope="col">
                  Standort
                  </th>
                  <th  scope="col">
                  Datum
                  </th>
                  <div className="tooltip">
             Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> 
                </tr>
              </thead>
              <tbody>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Konstruktion Aufsicht     
                   <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div>
                 </td>
                  <td className="job-icon">Website Supervisor</td>
                  <td className="loc-icon">Hannover</td>
                  <td className="cal-icon">2024-06-14</td> 
              
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Speichern Manager
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Speichern Management</td>
                  <td className="loc-icon">Munich</td>
                  <td className="cal-icon">2024-06-05</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Speichern Hüterin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Speichern Halten</td>
                  <td className="loc-icon">Cologne</td>
                  <td className="cal-icon">2024-06-12</td>  
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Logistik Koordinatorin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Koordinatorin</td>
                  <td className="loc-icon">Frankfurt</td>
                  <td className="cal-icon">2024-06-12</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Konstruktion Planerin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Planerin</td>
                  <td className="loc-icon">Dortmund</td>
                  <td className="cal-icon">2024-06-12</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Landschaft Architektin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Architektin</td>
                  <td className="loc-icon">Augsburg</td>
                  <td className="cal-icon">2024-06-08</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Bürgerlich Maschinenbau
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Bürgerlich Ingenieurin</td>
                  <td className="loc-icon">Berlin</td>
                  <td className="cal-icon">2024-05-04</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Konstruktion Schätzer
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Kostenschätzung</td>
                  <td className="loc-icon">Oldenburg</td>
                  <td className="cal-icon">2024-05-01</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Architektin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Architektur Design</td>
                  <td className="loc-icon">Jena</td>
                  <td className="cal-icon">2024-04-06</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Mengenvermesser
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Massenermittlung</td>
                  <td className="loc-icon">Steinbach</td>
                  <td className="cal-icon">2024-02-28</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Sicherheitsbeauftragter
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Gesundheit und Sicherheit</td>
                  <td className="loc-icon">Chemnitz</td>
                  <td className="cal-icon">2023-09-01</td>
                  
                </tr>

                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Bauarbeiter
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Allgemeine Konstruktion</td>
                  <td className="loc-icon">Brandenburg</td>
                  <td className="cal-icon">2023-08-14</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Elektrikerin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Elektroinstallation</td>
                  <td className="loc-icon">Bergheim</td>
                  <td className="cal-icon">2022-07-11</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Klempnerin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Sanitärdienste</td>
                  <td className="loc-icon">Hattingen</td>
                  <td className="cal-icon">2022-01-07</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Tischler
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Zimmerei</td>
                  <td className="loc-icon">Berlin</td>
                  <td className="cal-icon">2022-01-06</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Mason
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Mauerwerk</td>
                  <td className="loc-icon">Hattingen</td>
                  <td className="cal-icon">2021-09-01</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Geotechnisch&nbsp;Ingenieurin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Ingenieurin</td>
                  <td className="loc-icon">Saarbrücken</td>
                  <td className="cal-icon">2021-08-06</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Bauprojektkoordinator
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Koordinatorin</td>
                  <td className="loc-icon">Leipzig</td>
                  <td className="cal-icon">2021-07-15</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Gesundheits- und Sicherheitsbeauftragter
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Sicherheitsbeauftragter</td>
                  <td className="loc-icon">Nuremberg</td>
                  <td className="cal-icon">2021-06-11</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Bauplaner
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Planerin</td>
                  <td className="loc-icon"> Essen</td>
                  <td className="cal-icon">2020-12-28</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Baggerfahrer
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Ausrüstungsbetrieb</td>
                  <td className="loc-icon">Marl</td>
                  <td className="cal-icon">2020-12-05</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Strukturell&nbsp;Ingenieurin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Strukturell&nbsp;Maschinenbau</td>
                  <td className="loc-icon">Berlin</td>
                  <td className="cal-icon">2020-08-15</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Innenarchitekt
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Innenarchitektur</td>
                  <td className="loc-icon">Oldenburg</td>
                  <td className="cal-icon">2020-08-02</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Landvermesser  <div className="tooltip">
             Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Landbefragung</td>
                  <td className="loc-icon">Berlin</td>
                  <td className="cal-icon">2020-07-15</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Bauinspektor
                  <div className="tooltip">
             Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon"> Bauuntersuchung</td>
                  <td className="loc-icon">Wolfsburg</td>
                  <td className="cal-icon">2020-06-07</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Projektkoordinator
                  <div className="tooltip">
             Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Projekt-Koordination</td>
                  <td className="loc-icon">Hattingen</td>
                  <td className="cal-icon">2020-05-24</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Dachdecker
                  <div className="tooltip">
             Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div>  </td>
                  <td className="job-icon">Dachdienste</td>
                  <td className="loc-icon">Saarbrücken</td>
                  <td className="cal-icon">2020-05-14</td>

                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Trainerin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Bautrainer</td>
                  <td className="loc-icon">Chemnitz</td>
                  <td className="cal-icon">2019-08-25</td>
                 
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Personal
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">HR</td>
                  <td className="loc-icon">Berlin</td>
                  <td className="cal-icon">2018-11-24</td>
                  
                </tr>
                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Malerin
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Malen und Dekorieren</td>
                  <td className="loc-icon">Kronshagen</td>
                  <td className="cal-icon">2018-09-24</td>
                  
                </tr>

                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Stahl -Eektor
                  <div className="tooltip">
             Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">Stahlstahl Erektion</td>
                  <td className="loc-icon">hattingen</td>
                  <td className="cal-icon">2018-09-18</td>
                  
                </tr>

                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">Verfasser
                  <div className="tooltip">
              Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div> </td>
                  <td className="job-icon">Zeichnen und Design</td>
                  <td className="loc-icon">Berlin</td>
                  <td className="cal-icon">2016-10-08</td>
                  
                </tr>

                <tr onClick={handleRowClick} className="table-row">
                  <td className="table-row">HLK -Techniker
                  <div className="tooltip">
             Bitte teilen Sie Ihren Lebenslauf mit. Wenn Ihr Profil in die engere Wahl kommt, wird unser Team bald bei Ihnen melden
            </div></td>
                  <td className="job-icon">
                    Heizungs-, Lüftungs-und Klimaanlagen
                  </td>
                  <td className="loc-icon">Hürth</td>
                  <td className="cal-icon">2016-05-12</td>
                  
                </tr>
              </tbody>
            </Table>
          </Row>
        </Container>
      </section>
      {showButton && (
                <button onClick={scrollToTop} style={styles.button}>
                    ↑Zurück nach oben
                </button>
            )}
    </Fragment>
  );
}

export default Careeropportunities;
