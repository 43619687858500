import { Fragment } from 'react';
import React from 'react';
import { Container,Nav,Navbar,Col,NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import "../Component/Header.css";
import logo from "../assets/images/consger1.jpeg";
const Header =()=>(
    <Fragment>
          <Navbar expand="lg" className="bg-body-tertiary sticky-navbar">
         <Container>
         <Col lg={2}>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} width={200} height={150}/>
        </Navbar.Brand>
        </Col>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Col lg={10}>
          <Nav className="me-2">
            <Nav.Link  className='me-3' as ={Link} to="/">Heim</Nav.Link>
            <Nav.Link as={Link} to="/about-us" className='me-3'>Um&nbsp;uns</Nav.Link>
            <Nav.Link as={Link} to="/what-we-do"className='me-3'>Was&nbsp;Wir&nbsp;Tun</Nav.Link>
            <Nav.Link as={Link} to="/projects" className='me-3'>Projekte</Nav.Link>
            <Nav.Link className='me-3' as ={Link} to="/career-opportunities">Karriere&nbsp;Gelegenheiten</Nav.Link>
            <Nav.Link as ={Link} to="/news" className='me-3'>Nachricht</Nav.Link>
            <Nav.Link as={Link} to="/contact" className='me-3'>Kontakt</Nav.Link>
            <NavDropdown title="Sprache" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://consger.com/">Deutsch</NavDropdown.Item>
              <NavDropdown.Item href="https://consger.com/eng_file/">
             Englisch
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          </Col>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </Fragment>
  );
export default Header;