import React, { Fragment } from 'react'
import { Container,Row,Col } from 'react-bootstrap';
import person from "../assets/images/perso1.png";
function AboutUs() {
  return(
    <Fragment> 
     <section className="yellow-bg" id="about-us">
        <Container>
          <h2 className="pt-4">Consger</h2>
          <h2 className="pt-2 mb-0">
            Ist ein Lieferant für die internationale Bauindustrie.
          </h2>
        </Container>
      </section>
      <section className="main-banner-head">
        <Row>
          <Col lg={6}>
            <Container>
              <h3 className="pt-3 pb-3">Unternehmensgeschichte und Ziele</h3>
              <p>
                Das Unternehmen Conser wurde in schlechtem Neuenahr-Ahrweiler, Deutschland, gegründet und ist
Registriert als internationale Bauunternehmen
Ziel ist es, die Zukunft des Bauwesens durch wegweisende umweltfreundliche Gebäude zu gestalten
Praktiken und Förderung langfristiger Partnerschaften
Mit Kunden und Gemeinden, spezialisiert auf die vollständige Versorgung von
Waren in die Bauindustrie. <br/> Das Unternehmen verpflichtet sich kontinuierlich
Verbesserung, Investitionen in die neuesten Technologien und Mitarbeiterentwicklung zu
Halten Sie seinen Wettbewerbsvorteil beibehalten.
Letztendlich wollen wir weltweit wachsen und operieren.
Erweitern Sie unser Geschäft sogar über diese Märkte hinaus.
              </p>
              <p>
                Das Erreichen dieser Ziele umfasst unter anderem unter anderem.
Unterzeichnung langfristiger Händler- und Lizenzverträge überall
Die Welt und an internationalen Ausschreibungen teilnehmen, wo
Conger ist entweder der Hauptauftragnehmer oder a
Subunternehmer.
              </p>
              <p className="para-mobile">
               Unser Team, das aus einem Geschäftsführer besteht, Aktionäre,
Und sachkundige Manager haben umfangreiche Erfahrung in zahlreichen
Bedeutende globale Marktplätze.
              </p>
            </Container>
          </Col>
          <Col lg={6}>
            <img src={person} className="person" />
          </Col>
        </Row>
      </section>
      </Fragment>
  );
}

export default AboutUs;