import React, { Component } from 'react';
import './App.css';
import Header from "../src/Component/Header";
import Footer from "../src/Component/Footer";
import Routing from "../src/route/route";
class App extends Component {
  render() {
    return (
      <div>
      <Header/>
      <Routing/>
      <Footer/>
      </div>
    );
  }
}

export default App;
