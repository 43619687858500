import React from 'react'
import { Container,Row } from 'react-bootstrap'
import { PiMapPinFill } from "react-icons/pi";
import "../App.css";
function Contact() {
  return (
    <>   
    <section>
    <Container>   
      <h2 className=" pt-5 contact-head">Kontaktiere uns</h2>
      <p  className="partner-head">Mit unseren engagierten Mitarbeitern werden Sie nie herumhängen lassen</p>
            </Container>
            <Container>
              <Row>
                <div className="d-flex pt-4">
                  <div>
                  <PiMapPinFill className="pin"/>
                  </div>
                  <div>
                    <h3 >Adresse</h3>
                    <p className='address-bar'>Ummelner Straße <br/>12-14 , 33649 <br/>Bielefeld, Germany </p>
                    <div className="pt-3"><span>Post&nbsp;Zu&nbsp;:</span>&nbsp;<a className="hr-consger" href="mailto:hr@consger.com">hr@Consger.com</a></div>
                    <hr/>
                  </div>
                </div>

                </Row>

                </Container>
    </section>
</>
  )
}

export default Contact;