import React from 'react'
import {Row,Col} from "react-bootstrap";
import const1 from "../assets/images/const1.jpg";
import what2 from "../assets/images/what2.jpg";
import what3 from "../assets/images/what3.jpg";
function whatwedo() {
  return (
    <section className="main-banner-head" id="what-we-do">
      <h1 className="pt-5 mb-0 what-section">WAS WIR TUN</h1>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <h2 className="what-head-sub pt-5">1 - Allgemeine / strukturelle Bauherren</h2>
              <h5 className="what-sub-2">Versorgung sowie Bauingenieurwesen</h5>
              <p>
               Die folgenden Produkte werden fachmännisch von Conser versorgt:
                </p>
                <ul>    
                <li>Rohstoffe, einschließlich Sand, Beton, Ziegel und
Granatwerfer.</li>
                <li> Gebäudelieferungen sowohl für Innen- als auch für Äußere
Gebäude.</li>
                <li> Einschließlich Farbe, Wandbedeckungen, Teppichböden, Steine,
Metall-, Stahl-, Holz-, Glas- und Plastik -Tischlerei und Mauerwerk</li>
                <li>
               Materialien für Isolationsgerüste und Stahlkonstruktionen
Dachlieferungen
                </li>
                <li> Geräte, Zubehör und Teile für elektrische und elektronische Installationen und Ausstattung.</li>
                <li>
               Werkzeuge, Zubehör, Ersatzteile, Verbrauchsmaterialien (z. B. Muttern und Schrauben usw.)
                </li>
                <li>Wasserversorgungsgeräte, Wasserrohre, Klempner
Sanitäranlagen</li>
                <li>
               Heizungs- und Klimaanlagen, Systeme,
Tischlerei, Möbel und Armaturen
                </li>
                <li>
              Fenster und Türlösungen,
Bodenbelag,
Heimtechnologie,
Sicherheitstechnologie.
                </li>
                </ul>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <img src={const1} className="w-100"/>
            </Col>
          </Row>
      <Row>
      <Col sm={12} md={12} lg={6} className="what-mobile">
              <img src={what3} className="w-100"/>
            </Col>
      <Col sm={12} md={12} lg={6} className="pt-5 pb-5">
              <h2 className="pt-5 what-head-sub">2 - Außenbauer</h2>
              <h5 className="what-sub-2">Versorgung der Außenbauer</h5>
              <p>
              Die folgenden Aufgaben von außen können übertragen werden
Durch Konsum mit dem Erwerb von Maschinen,
Werkzeuge, Geräte und Zubehör: Terrassen, Garagen, Automobilanschlüsse,
und schwimmende Decks aus Gartenausgrabungen für Pflastersteine
mit Wänden und Zäunen <br/> Laibs und Dachrinnen mager zu Pools und Teichen
              </p>
            </Col>
      </Row>
      <Row>
      <Col sm={12} md={12} lg={6} className="pt-5">
              <h2 className="pt-5 what-head-sub">3 - Nach-Pflege Liefern Dienstleistungen</h2>
              <h5 className="what-sub-2">Verkaufs- und Versorgungsdienstleistungen</h5>
              <p>
             Nachdem die Bauprojekte beendet sind, können sich unsere Kunden auf uns verlassen
Um ihre Bedürfnisse nach Materialien, Ausrüstung und Verbrauchsmaterial zu erfüllen
Sowie Ersatzteile und Zubehör zu
Aktualisieren, ersetzen und/oder erweitern Sie die aktuellen Installationen.

              </p>
            </Col>
            <Col sm={12} md={12} lg={6} className="what-last-mobile">
              <img src={what2} className="w-100"/>
            </Col>
      </Row>
      </section>
  )
}

export default whatwedo