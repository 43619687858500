import React from 'react'
import {Switch, Route } from 'react-router-dom';
import Home from "../pages/home";
import Contact from '../pages/Contact';
import Career from "../pages/Careeropportunities";
import News from "../pages/News";
import Aboutus from '../pages/Aboutus';
import Whatwedo from "../pages/whatwedo";
import Projects from '../pages/Projects';
const Routing = () => {
  return (
    <Switch>
    <Route exact path= "/"  component ={Home}/> 
    <Route path="/career-opportunities" component ={Career}/>
    <Route path="/contact" component={Contact}/>
    <Route path="/news" component={News}/>
    <Route path="/about-us" component ={Aboutus}/>
    <Route path="/what-we-do" component ={Whatwedo}/>
    <Route path="/projects" component ={Projects}/>
    </Switch>
  )
}

export default Routing